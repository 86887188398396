var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-aws","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v("Servidores RDS na AWS")])]},proxy:true}])},[_c('v-text-field',{staticClass:"ml-auto mt-4 break-search",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{attrs:{"headers":[
            {
              text: 'Servidor',
              value: 'name'
            },
            {
              text: 'Versão do Banco de Dados',
              value: 'engine'
            },
            {
              text: 'Endpoint',
              value: 'endpoint'
            },
            {
              text: 'Tipo na AWS',
              value: 'instance_type'
            },
            {
              text: 'Estado',
              value: 'status'
            }
          ],"item-value":"id","show-select":"","items":_vm.data,"search":_vm.search,"sort-by":['name'],"headerProps":{
            sortByText: 'Ordenar Por'
          },"disable-pagination":"","mobile-breakpoint":800,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":"","no-results-text":"Nenhum registro correspondente encontrado"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('aws-instance-status',{attrs:{"status":item.status}})]}}],null,true),model:{value:(_vm.selectedInstances),callback:function ($$v) {_vm.selectedInstances=$$v},expression:"selectedInstances"}}),_c('v-divider',{staticClass:"mb-5"}),(!_vm.loading)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mt-n5",staticStyle:{"float":"right"},attrs:{"color":"red darken-1","min-width":"100","disabled":!_vm.runningInstances.length,"loading":_vm.loadingStop},on:{"click":function($event){return _vm.desligarSelecionados()}}},[_vm._v(" Desligar Selecionados ")]),_c('v-btn',{staticClass:"mt-n5 mr-2",staticStyle:{"float":"right"},attrs:{"color":"green darken-1","min-width":"100","disabled":!_vm.stoppedInstances.length,"loading":_vm.loadingStart},on:{"click":function($event){return _vm.ligarSelecionados()}}},[_vm._v(" Ligar Selecionados ")])],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }