<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-aws"
          inline
          class="px-5 py-4 ml-0"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">Servidores RDS na AWS</div>
          </template>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto mt-4 break-search"
            label="Procurar"
            hide-details
            single-line
            style="max-width: 250px"
          />
          <v-divider class="mt-10" />
          <v-data-table
            v-model="selectedInstances"
            :headers="[
              {
                text: 'Servidor',
                value: 'name'
              },
              {
                text: 'Versão do Banco de Dados',
                value: 'engine'
              },
              {
                text: 'Endpoint',
                value: 'endpoint'
              },
              {
                text: 'Tipo na AWS',
                value: 'instance_type'
              },
              {
                text: 'Estado',
                value: 'status'
              }
            ]"
            item-value="id"
            show-select
            :items="data"
            :search.sync="search"
            :sort-by="['name']"
            :headerProps="{
              sortByText: 'Ordenar Por'
            }"
            disable-pagination
            :mobile-breakpoint="800"
            :loading="loading"
            :hide-default-header="loading"
            hide-default-footer
            no-results-text="Nenhum registro correspondente encontrado"
          >
            <template v-slot:[`item.status`]="{ item }">
              <aws-instance-status :status="item.status" />
            </template>
          </v-data-table>
          <v-divider class="mb-5" />
          <v-row v-if="!loading">
            <v-col cols="12">
              <v-btn
                color="red darken-1"
                min-width="100"
                class="mt-n5"
                style="float: right"
                :disabled="!runningInstances.length"
                :loading="loadingStop"
                @click="desligarSelecionados()"
              >
                Desligar Selecionados
              </v-btn>
              <v-btn
                color="green darken-1"
                min-width="100"
                class="mt-n5 mr-2"
                style="float: right"
                :disabled="!stoppedInstances.length"
                :loading="loadingStart"
                @click="ligarSelecionados()"
              >
                Ligar Selecionados
              </v-btn>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ServidoresAwsRdsService from '@/services/aws/ServidoresAwsRdsService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import AwsInstanceStatus from '@/components/general/AwsInstanceStatus.vue';

export default {
  name: 'ServidoresAwsRds',
  mixins: [refreshDataMixins],
  components: {
    AwsInstanceStatus
  },
  data() {
    return {
      data: [],
      selectedInstances: [],
      search: '',
      loading: false,
      loadingStart: false,
      loadingStop: false
    };
  },
  mounted() {
    this.refreshData(this.getServidores);
  },
  computed: {
    runningInstances() {
      return this.selectedInstances.filter((instance) =>
        ['running', 'available'].includes(instance.status)
      );
    },
    stoppedInstances() {
      return this.selectedInstances.filter(
        (instance) => instance.status === 'stopped'
      );
    }
  },
  methods: {
    getServidores() {
      this.data = [];
      this.loading = true;
      ServidoresAwsRdsService.getServidores()
        .then(({ data }) => {
          this.data = data;
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error(
            'Erro ao recuperar os servidores RDS do sistema.',
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading = false));
    },
    ligarSelecionados() {
      this.loadingStart = true;
      ServidoresAwsRdsService.ligarServidores(this.stoppedInstances)
        .then(() => {
          this.selectedInstances = [];
          this.$toast.success(
            'Comando para ligar os servidores enviado com sucesso.',
            '',
            {
              position: 'topRight'
            }
          );
          this.getServidores();
        })
        .catch(() => {
          this.$toast.error(
            'Ocorreu um erro ao tentar ligar os servidores.',
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loadingStart = false));
    },
    desligarSelecionados() {
      if (
        !confirm('Tem certeza que deseja desligar os servidores selecionados?')
      ) {
        return false;
      }
      this.loadingStop = true;
      ServidoresAwsRdsService.desligarServidores(this.runningInstances)
        .then(() => {
          this.selectedInstances = [];
          this.$toast.success(
            'Comando para desligar os servidores enviado com sucesso.',
            '',
            {
              position: 'topRight'
            }
          );
          this.getServidores();
        })
        .catch(() => {
          this.$toast.error(
            'Ocorreu um erro ao tentar desligar os servidores.',
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loadingStop = false));
    }
  }
};
</script>
